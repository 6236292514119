@import '../variables';

.contact-block {
  @apply grid grid-cols-2 sm:grid-cols-3 shadow-2xl;
  max-width: 850px;
  width: 100%;

  &.other-big {
    .left {
      @apply col-span-1;
    }
    .right {
      @apply col-span-2;
    }
  }

  h2 {
    font-family: $main-font;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
  }

  .left {
    @apply col-span-2;
    background: white;
    padding: 2rem;

  }
  .right {
    color: white;
    padding: 2rem;
    @apply bg-bh-green col-span-2 sm:col-span-1;

    ul {
      li {
        &.title {
          font-weight: bold;
        }

        a {
          color: white;
          &:hover {
            color: white;
            text-decoration: underline;
          }
        }
      }
    }

    .social-title {
      font-weight: bold;
    }
    .contact-icons {
      ul {
        display: flex;
        li {
          font-size: 1.2em;
          margin-right: 10px;
        }
      }
      i {
        opacity: .5;
        transition: .3s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.template-contact {
  .contact-background-image {
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    height: 60%;
  }
}