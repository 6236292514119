.header {
  @apply bg-bh-white;
  padding-top: 50px;
  padding-bottom: 50px;
  position: sticky;
  z-index: 111111;
  transition: all .5s;
  top: 0;

  a {
    text-decoration: none;
  }

  &.scrolled {
    z-index: 11111511;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.5);

    .top-menu-top-bar {
      top: 0 !important;
      //opacity: 0 !important;
      //transform: translateY(-200%) ;
    }
  }

  .top-menu {
    position: relative;
    display: flex;
    align-items: center;

    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;

      li {
        @apply text-bh-dark;
        margin-left: 25px;
      }
    }

    .top-menu-top-bar {
      transform: translateY(0);
      transition: all .2s;
      top: -10px;
      position: absolute;
      right: 0;
      opacity: .4;
      font-size: 15px;
      display: flex;

    }
  }

  .phone-menu-button {
    @apply text-7xl text-bh-green my-auto;
    z-index: 11111513;
  }
}

.phone-menu-wrapper {
  @apply fixed top-0 left-0 h-full w-full bg-bh-green text-bh-white lg:hidden;
  z-index: 11111512;
  display: none;

  .phone-menu {
    @apply relative flex h-fit w-full my-24 pt-24 justify-center;

    .menu-hoofdmenu-container {
      .menu {
        @apply text-7xl flex flex-col gap-6;

        .menu-item {
          &.current-menu-item, &.menu-item-has-children {
            @apply text-bh-white;
          }

          a {
            @apply no-underline;
          }
        }
      }
    }

    i {
      @apply absolute right-[5%] top-0 text-7xl;
    }
  }

  &.open {
    display: flex;
  }
}