.image_header {
  position: relative;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 1200px;
    max-height: 300px;
  }

  &.full-width {
    img {
      max-width: 100%;
    }
  }
}