@import '../../variables';

.wedding-card {
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all .2s;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  }

  padding: 10px;
  img {
    height: 350px;
    width: 100%;
    object-fit: cover;
  }
  .title {
    font-size: 30px;
    line-height: 49px;
    text-align: center;
    padding: 15px 0;
    font-family: $accent-font;
  }
 }