.archive-bruiloften {
  .bruiloft-item {
    // even
    &:nth-child(even) {
      @apply bg-bh-green bg-opacity-40;

      .text-col {
        order: -1;
      }
    }
    // odd
    &:nth-child(odd) {
      @apply bg-bh-pink bg-opacity-40;
    }

    &:last-child{
      @apply bg-bh-white;
    }

    .content {}
  }
}