@import '../../variables';

.blog_posts {
  &_header {
    @extend %header-with-background;
    font-size: clamp(1.5rem, 75px, 16vw) !important;
    line-height: clamp(1.5rem, 80px, 20vw) !important;
    color: black !important;
    &:before {
      @apply bg-bh-pink;
    }
  }

  .content {
    position: relative;
    padding-bottom: 30px;
    @apply bg-bh-green bg-opacity-40;
    &:before {
      position: absolute;
      content: '';
      height: 30px;
      width: 100%;
      top: 0;
      left: 0;
      @apply bg-bh-white;
    }

    .blogs {
      @apply grid md:grid-cols-3 gap-4;

      &:hover {
        .blog {
          opacity: .5;
        }
      }

      .blog {
        transition: all .2s;
        position: relative;
        img {
          width: 100%;
        }

        h3 {
          @apply mt-4;
          font-family: $main-font;
          font-size: 25px;
          line-height: 30px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
 }