.locatie-slider {
  display: grid;

  ul {
    li {
      img {
        height: 308px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .arrows {
    order: 3;

    button {
      @apply text-bh-green;
      &:first-of-type {
        margin-right: 15px;
      }
    }
  }

  .splide__pagination {
    order: 2;
    justify-content: flex-start;

    li {
      &:first-of-type {
        .splide__pagination__page {
          margin-left: 0 !important;
        }
      }
      &:last-of-type {
        .splide__pagination__page {
          margin-right: 0 !important;
        }
      }
    }

    .splide__pagination__page {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: transparent;
      margin: 0 5px;
      transition: all 0.3s ease-in-out;
      border: 1px solid;
      @apply border-bh-green;

      &.is-active {
        @apply bg-bh-green;
      }

    }
  }
}