@import 'font';

$main-font: 'lato', sans-serif;
$header-font: 'Vidaloka', serif;

$accent-font: professor, sans-serif;

.custom-spacing {
  padding-left: 5%;
  padding-right: 5%;
}

%header-with-background {
  font-size: clamp(1.5rem, 125px, 14vw);
  line-height: clamp(1.5rem, 150px, 15vw);

  padding: 0 3rem;
  position: relative;
  display: inline-block;
  color: white;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    max-height: 60px;
  }
}

@mixin HeaderWithBackground() {
  font-size: 125px;
  line-height: 150px;
  padding: 0 3rem;
  position: relative;
  display: inline-block;
  color: white;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    max-height: 60px;
  }

  &.large {
    &:before {
      height: 100%;
      max-height: 100%;
    }
  }
}