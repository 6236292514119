@import '../variables';

.archive-diensten {
  .dienst {
    // even
    &:nth-child(even) {
      @apply bg-bh-green bg-opacity-40;

      .dienst-content {
        order: -1;
      }
    }

    // odd
    &:nth-child(odd) {
      @apply bg-bh-pink bg-opacity-40;
    }

    &:last-child {
      @apply bg-bh-white;
    }

    .dienst-image {
      img {
        height: 408px;
      }
    }

    .dienst-content {
      .title {
        @apply text-bh-green;
        font-size: 25px;
        line-height: 30px;
        font-family: $main-font;
      }

      .sub-title {
        @apply text-bh-green;
        font-size: 18px;
        line-height: 30px;
        font-family: $main-font;
        font-weight: bold;
      }

      .the_content {
        max-width: 600px;
        margin-bottom: 30px;
        margin-top: 15px;
      }
    }
  }
}