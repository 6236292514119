@import '../../variables';

.gallery {

  .header {
    @include HeaderWithBackground();
    color: black;
    font-size: clamp(1.5rem, 75px, 7vw) !important;
    line-height: clamp(1.5rem, 80px, 20vw) !important;

    &:before {
      @apply bg-bh-pink;
    }
  }

  .images {
    @apply grid md:grid-cols-2 gap-4;

    img {
      height: 410px;
      object-fit: cover;
      cursor: pointer;
      @apply w-full;
    }
  }

  .links {
    display: none;
  }
}