@import '../../variables';

.team-person {
  img {
    height: 270px;
    width: 100%;
    object-fit: cover;
    margin-right: 10px;
  }
  .info {
    position: relative;
    background: white;
    margin-top: -20px;

    padding: 15px 5px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all .2s;

    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    }
    h3 {
      font-family: $main-font;
      text-align: center;
      font-weight: bold;
    }
    span {
      font-style: italic;
      text-align: center;
    }
  }
 }