@import '../variables';

.archive-locaties {
  .archive-column {
    @apply py-16;

    // even
    &:nth-child(even) {
      @apply bg-bh-green bg-opacity-40;

      .locatie-content {
        order: -1;
      }

      .locatie-slider {
        .splide__pagination {
          justify-content: flex-end;
        }
        .arrows {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    &:nth-child(odd) {
      @apply bg-bh-pink bg-opacity-40;
    }

    &:last-child{
      @apply bg-bh-white;
    }
  }
}