@import '../variables';

body.page-template-voorwaarden {
  .voorwaarden{
    h2, h3, h4 {
      @apply text-bh-green mt-6 mb-2;
      font-family: $header-font;
      font-size: 25px;
      line-height: 31px;
      display: block;
    }
  }
}