.footer {
    @apply bg-bh-pink bg-opacity-40;


  .bottom-bar {
    @apply bg-bh-white;

    a {
      color: black;
      opacity: .4;
    }
  }

  .logo {
    width: 100%;
    max-width: 340px;
  }

  .nav-item {
    .title {
      font-weight: bold;
    }
  }

  .bottom-bar {
    ul {
      @apply block md:flex;
      justify-content: center;
      align-items: center;

      li {
        padding: 12px 12px;
      }
    }
  }

  .contact-icons {
    margin-top: 20px;
    ul {
      width: 100%;
      justify-content: flex-start;
      display: flex;
      opacity: .6;
      font-size: 1.2em;
      li {
        margin-right: 15px;
      }
    }
  }
}