@import "../../variables";

.filter-context {
  .filter-item {
    margin-bottom: 15px;

    > label {
      @apply text-bh-green;
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }

    &.filter-item-checkbox {
      >label {
        margin-bottom: 15px;
      }

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] {
        &+label {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 15px;
          padding: 5px 15px;
          display: inline-block;

          position: relative;
          @apply border-bh-green text-bh-green;
          border-radius: 17px;
          background: white;
          border: 1px solid;
          outline: none !important;
        }


        &:checked+label {
          @apply bg-bh-green text-white;
        }
      }
    }

    &.filter-item-range {
      input {
        min-width: 200px;
        accent-color: #5A7C74;
      }
      .inputs {
        display: inline-block;
        .top {
          display: flex;
          justify-content: space-between;
          span  {
            font-size: 10px;
            line-height: 12px;
            @apply text-bh-green;
          }
        }
        .bottom {
          margin-bottom: 15px;
          position: relative;
          span {
            position: absolute;
            font-size: 10px;
            line-height: 12px;
            @apply text-bh-green;

            &:not(:first-of-type):not(:last-of-type) {
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    &.filter-item-select {
      select {
        width: 100%;
        min-width: 140px;
        font-size: 18px;
        line-height: 20px;

        position: relative;
        @apply border-bh-green text-bh-green;
        border-radius: 17px;
        background: white;
        border: 1px solid;
        outline: none !important;

        &::-ms-expand {
          display: none;
        }
      }

      .select-container {
        position: relative;
        display: inline-block;
        &:after {
          @apply text-bh-green;
          content: "\f0d7";
          position: absolute;
          top: 50%;
          right: 10px;
          font-weight: 900;
          transform: translateY(-50%);
          font-family: "Font Awesome 5 Pro";
          font-size: 1.2em;
        }
      }
    }
  }
}
