@import '../../variables';

.content_blocks {
  a {
    font-weight: bold;
  }


  .content-block-text {
    margin-bottom: 15px;

    h1,h2 {
      @include HeaderWithBackground();
      color: black;
      font-size: clamp(1.5rem, 75px, 16vw) !important;
      line-height: clamp(1.5rem, 95px, 20vw) !important;
      &:before {
        @apply bg-bh-pink;
      }
    }
    h3,h4,h5 {
      font-family: $main-font;
      font-size: 25px;
      line-height: 30px;
      margin-left: 2rem;
      @apply text-bh-green;
    }
    p, ul {
      @apply md:ml-8;
      margin-bottom: 1.5rem;
    }

    ul {
      margin-left: 4rem;
      list-style-type: circle;
    }
  }

  .content-block-quote {
    font-size: 30px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    .content {
      max-width: 960px;
      border-left: 11px solid;
      padding-left: 25px;
      > * {
        opacity: .7;
      }
      @apply border-bh-pink;
    }
  }
 }