@import '../variables';

.single-bruiloft {
  .header-img {
    @apply w-full;
    max-height: 850px;
    object-fit: cover;
  }
  .header-title {
    @apply ml-8 absolute -bottom-8 left-0 z-10;
  }

  .bruiloft-usps {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .bruiloft-usp {
      display: flex;

      border-bottom: 2px solid #ECECEC;
      &:first-of-type {
        border-top: 2px solid #ECECEC;
      }

      @apply py-12;

      .icon {
        width: 50px;
        margin-right: 15px;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        img {
          opacity: 20%;
          max-width: 100%;
        }
      }
      .content {
        .title {
          font-family: $header-font;
          font-size: 25px;
          line-height: 31px;
          display: block;
        }
        .value {
          font-size: 18px;
          line-height: 31px;
          font-family: $main-font;
          font-weight: lighter;
        }
      }
    }
  }
}