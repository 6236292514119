@import '../../variables';

.diensten-slider {

  .splide__slide {
    perspective: 60px;
    position: relative;
    padding: 25px 0;

    width: 100%;
    height: 600px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 99%;
      height: 100%;

      box-shadow: 0 0 10px rgba(0,0,0,.5);
    }

    .inner {
      width: 100%;
      height: 100%;
      transition: all .5s;
      transform: translate3d(1,1,1);
      position: relative;
    }

    .text {
      transition: all .5s  cubic-bezier(0.175, 0.885, 0.32, 1.275);
      position: absolute;
      top: 50%;
      color: white;
      left: -15px;
      opacity: 1;
      text-shadow: 0 0 10px rgba(0,0,0,.5);

      transform: translateY(-50%);

      h2 {
        font-family: $header-font;
        //font-size: 75px;
        //line-height: 92px;

        font-size: clamp(1.5rem, 75px, 12vw);
        line-height: clamp(1.5rem, 92px, 13vw);
      }
    }

    &.is-active {
      img {
        box-shadow: 0 0 20px rgba(0,0,0,.7);
      }
    }

    &:not(.is-active) {
      .text {
        transform: translateY(50%);
        visibility: hidden;
        opacity: 0;
      }
      .inner {
        opacity: .5;
      }
    }

    &.is-prev, &.is-next {
      $scale: scale3d(.8, .8, 1);

      &.is-prev {
        .inner {
          transform: $scale rotateY(1deg);
          //transform: $scale rotate3d(-1, -3, 0, 45deg)
        }
      }
      &.is-next {
        .inner {
          transform: $scale rotateY(-1deg);
          //transform: $scale rotate3d(-1, 3, 0, 45deg)
        }
      }
    }
  }
}

