@import 'variables';


body {
  font-size: 18px;
  line-height: 2.5rem;
  font-family: $main-font;

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  max-width: 100vw;
  overflow-x: hidden;
}

main {
  flex: 1;
}

h1,h2,h3,h4,h5 {
  font-family: $header-font;
}

h1 {
  font-size: 125px;
  line-height: 152px;
}

.bold {
  font-weight: bold;
}

a {
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}


@import 'components';



.green-header {
  @extend %header-with-background;

  &:before {
    @apply bg-bh-green;
  }
}

.pink-header {
  @include HeaderWithBackground();

  font-size: clamp(1.5rem, 75px, 16vw) !important;
  line-height: clamp(1.5rem, 95px, 20vw) !important;

  &.black-text {
    @apply text-black;
  }

  &:before {
    @apply bg-bh-pink;
  }
}

.btn {
  display: inline-block;
  text-decoration: none !important;

  &.btn-green {
    padding: 5px 15px;
    border: 2px solid;
    border-radius: 21px;
    transition: all .2s;

    @apply bg-bh-green text-white border-bh-green hover:bg-white hover:text-bh-green;
  }

  &.btn-pink {
    padding: 5px 15px;
    border: 2px solid;
    position: relative;
    @apply border-bh-dark text-bh-dark;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 5px;
      left: 5px;
      @apply bg-bh-pink;
      transition: all .2s;
    }

    &:hover:before {
      top: 0;
      left: 0;
    }
  }

  &:after {
    margin-left: 10px;
    content: '\f061';
    font-weight: 400;
    font-family: 'Font Awesome 5 Pro';
  }

  &.arrow-down {
    &:after {
      content: '\f063';
    }
  }

  &.no-arrow {
    &:after {
      display: none;
    }
  }

  &.btn-download {
    display: inline-block;
    border-radius: 4px;
    color: #fff;
    @apply bg-bh-green pl-8;


    &:after {
      @apply  py-4 px-4 ml-8;
      content: '\f063';
      display: inline-block;
      background: #77948D;
      border-radius: 4px;
      aspect-ratio: 1/1;
      transition: all .2s;
    }

    &:hover {
      &:after {
        @apply bg-bh-green;
      }
    }
  }
}

.header-slider {
  @apply relative;

  .arrows {
  @apply text-white text-6xl flex flex-row gap-12 absolute right-0 bottom-0;
    transform: translateY(2rem);
    z-index: 99;
  }
}

.the_content {
  p {
    margin-bottom: 15px;
    line-height: 2.5rem;
  }

  &.white {
    color: white;
  }
}


.stretched-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: -1;
}

.green-bg {
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 60%;
  @apply bg-bh-green bg-opacity-40;
}

.menu-item-has-children {
  &:hover {
    .sub-menu {
      max-height: 5000px;
      transition: all 1s ease-in-out,  opacity .1s ease-in-out;
      visibility: visible;
      opacity: 1;

    }
  }

  .sub-menu {
    flex-direction: column;
  li {
    margin: 0 !important;
    padding: 7.5px 15px;
  }
    a {
      white-space: nowrap;
    }
  }
}

.dropdown-container, .menu-item-has-children  {
  @apply text-bh-green;
  > span {
    cursor: pointer;
  }
  display: inline-block;
  position: relative;

  &.open {
    .dropdown-content {
      max-height: 5000px;
      transition: all 1s ease-in-out,  opacity .1s ease-in-out;
      visibility: visible;
      opacity: 1;
    }
  }

  .dropdown-content, .sub-menu {
    &:before {
      content:"\A";
      border-style: solid;
      border-width: 10px 10px 15px 10px;
      border-color: transparent transparent #fff transparent;
      position: absolute;
      top: -25px;
      right: 10px;
    }

    max-height: 0;
    transition: all 1s ease-in-out,  opacity 5s ease-in-out;
    overflow: hidden;

    transform: translateY(calc(100% + 15px));
    z-index: 1111;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    padding: 15px;
  }
}

.custom-form-styling {
  label {
    color: black;
    opacity: .35;
  }

  input:not([type="submit"]),textarea {
    padding-top: 0;
    outline: none !important;
    box-shadow: none !important;
    border: none;
    border-bottom: 2px solid;
    @apply border-bh-green;
  }

  input[type="submit"] {
    background: none;
    transition: all  .2s;
    @apply border-2 border-bh-green text-bh-green px-6 py-2;

    &:hover {
      @apply bg-bh-green text-white;
    }
  }
}


.two-column-text {
  column-count: 2;
}

.three-column-text {
  column-count: 3;
}

$small: 640px;
$medium: 768px;
$large: 1024px;
$xlarge: 1280px;

@media (max-width: $small) {
  .two-column-text {
    column-count: 1;
  }
  .three-column-text {
    column-count: 1;
  }
}

@media (min-width: $small) and (max-width: $medium) {
  .two-column-text {
    column-count: 1;
  }
  .three-column-text {
    column-count: 2;
  }
}