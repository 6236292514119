body.single-locaties {
  .container{
    &:before{
      display: none;
      content: none;
    }
  }

  .location-table {
    @apply h-fit p-24;

    h2{
      @apply font-bold text-5xl mb-6 text-center;
    }

    table {

      tr {
        @apply border-b border-white/10;

        &:last-child{
          border-bottom: none!important;
        }

        td {
          @apply py-4;

          &.title {
            @apply w-1/3 pr-4;
          }
        }
      }
    }
  }
}