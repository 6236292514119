@import '../../variables';

.bruiloften {
    @apply my-12;

    .bruiloft {
      padding: 15px;
      background: white;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      img {
        object-fit: cover;
        width: 100%;
        height: 550px;
      }
      span {
        margin-top: 25px;
        margin-bottom: 10px;
        font-family: $accent-font;
        font-size: 30px;
        line-height: 49px;
      }
    }
 }