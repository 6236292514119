.usps {
  .usp {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .icon {
      margin-right: 10px;
      @apply text-bh-green;
      i {
        font-size: 1.5em;
      }
    }
  }
 }